<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      image: this.value,
    };
  },
};
</script>

<template>
  <div class="illustration-description">
    <table >
      <tr>
        <th valign="top">{{ $t("imageType") }}:</th>
        <th v-html="image.aboutImgSv"></th>
      </tr>
      <tr>
        <th valign="top">{{ $t("authors") }}:</th>
        <th>{{ image.creatorSv }}</th>
      </tr>
      <tr>
        <th valign="top">{{$t("publicationYear")}}:</th>
        <th>{{ image.pubYearSv }}</th>
      </tr>
      <tr>
        <th valign="top">{{$t("imageNumber")}}:</th>
        <th>{{ image.ext_id }}</th>
      </tr>
      <tr>
        <th valign="top">{{$t("colorMode")}}:</th>
        <th>{{ $t("grayscale") }}</th>
      </tr>
      <tr>
        <th valign="top">{{ $t("fileSize") }}:</th>
        <th>{{ image.fileSize }} kB</th>
      </tr>
      <tr>
        <th valign="top">{{ $t("dimensions") }} (cm):</th>
        <th>{{ image.imageWidthCm }} &#215; {{ image.imageHeightCm }}</th>
      </tr>
      <tr>
        <th valign="top">{{ $t("dimensions") }} (px):</th>
        <th>{{ image.imageWidthPx }} &#215; {{ image.imageHeightPx }}</th>
      </tr>
      <tr>
        <th valign="top">{{ $t("resolution") }}:</th>
        <th>{{ image.resolution }} ppi</th>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
  .illustration-description{
    font-size: 16px !important;
        line-height: 29px;
        font-weight: 500;
  }

  th {
    &:last-of-type{
      padding-left: 2em;
    }
  }

  tr {
    > th {
      padding-bottom: .25rem;

      &:last-child {
        font-weight: 500;
      }
    }
  }
</style>
